import { useCallback, useMemo } from 'react';

import { useGetBeneficiariesLazyQuery } from '@/shared/generated/graphql';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { BeneficiaryDto } from '@/shared/lib/sj-orm/models/beneficiary.dto';
import { SharedDto } from '@/shared/lib/sj-orm/models/shared.dto';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';

export const useShared = () => {
  const sjDb = useSJDatabase();
  const { keyPair } = useKeyPairStore();
  const [getBeneficiaries] = useGetBeneficiariesLazyQuery();

  const getAllShared = useMemo(() => {
    return sjDb.collections.shared?.findMany(() => true) || [];
  }, [sjDb]);

  const getShared = useCallback(
    (id: string) => {
      return sjDb.collections.shared?.findOne((item) => item.id === id);
    },
    [sjDb],
  );

  const getSharedEntity = useCallback(
    (id: string, type: CollectionName) => {
      return sjDb.collections[type]?.findOne((item) => item.id === id);
    },
    [sjDb],
  );

  const addShared = useCallback(
    (dtoId: string, type: CollectionName, nextOwners: number[]) => {
      if (!keyPair?.publicKey) throw new Error('Public key is not defined');
      sjDb.collections.shared?.create(
        {
          id: sjDb.generateId(CollectionName.SHARED),
          dtoId: dtoId,
          type: type,
          nextOwners,
        },
        [keyPair.publicKey],
      );
    },
    [sjDb],
  );

  const deleteShared = useCallback(
    (dtoId: string) => {
      const sharedItems = sjDb.collections.shared?.findMany(
        (item) => item.dtoId === dtoId,
      );
      if (sharedItems)
        for (const shared of sharedItems) {
          sjDb.collections.shared?.remove(shared as unknown as SharedDto);
        }
    },
    [sjDb],
  );

  const getSharedNextOwners = useCallback(
    async (id: string, collection: CollectionName) => {
      const keys = sjDb.collections[collection]?.getPublicKeys(id);

      if (!keys) return [];

      const sharedPublicKeys = keys.filter((key) => key !== keyPair?.publicKey);

      if (!sharedPublicKeys.length) return [];

      const nextOwnersBEIds: { id: number; publicKey: string }[] = [];

      const beNextOwners = await getBeneficiaries();

      const filteredBENextOwners = beNextOwners?.data?.getBeneficiaries.filter(
        (no) => !!no.publicKey,
      );

      if (!filteredBENextOwners?.length) return [];

      for (const key of sharedPublicKeys.filter(Boolean)) {
        const nextOwnerBEData = filteredBENextOwners.find(
          (no) => no.publicKey === key,
        );

        if (!nextOwnerBEData) continue;

        nextOwnersBEIds.push({
          id: nextOwnerBEData.id,
          publicKey: nextOwnerBEData.publicKey!,
        });
      }

      if (!nextOwnersBEIds.length) return [];

      const resultNextOwners: (BeneficiaryDto & { publicKey: string })[] = [];

      for (const noDataForSearch of nextOwnersBEIds) {
        const nextOwner = sjDb.collections.beneficiaries?.findOne(
          (no) => no.centralDbProfileId === noDataForSearch.id,
        );

        if (!nextOwner) continue;

        resultNextOwners.push({
          ...nextOwner,
          publicKey: noDataForSearch.publicKey,
        });
      }

      return resultNextOwners;
    },
    [sjDb, keyPair],
  );

  return useMemo(
    () => ({
      getAllShared,
      getShared,
      getSharedEntity,
      addShared,
      deleteShared,
      getSharedNextOwners,
    }),
    [
      getAllShared,
      getShared,
      getSharedEntity,
      addShared,
      deleteShared,
      getSharedNextOwners,
    ],
  );
};
