import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Drawer, Row, Select, Typography } from 'antd';

import { useMnemonic } from '@/entities/mnemonic';
import { useNotifications } from '@/entities/notification';
import { useShared } from '@/entities/shared/use-shared';
import { useSharedSpaces } from '@/entities/shared-spaces';
import { CopyComponent } from '@/shared/components';
import {
  useCreateAssistantMutation,
  useGetBeneficiariesQuery,
} from '@/shared/generated/graphql';
import { getDtoRelatedToTargetDto } from '@/shared/lib/helpers/asset.helper';
import { Permission } from '@/shared/lib/secure-json/core/secure-json-collection/types';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';
import { BeneficiaryDto } from '@/shared/lib/sj-orm/models/beneficiary.dto';
import { NotificationType } from '@/shared/lib/sj-orm/models/notification.dto';
import { SharedSpacesDto } from '@/shared/lib/sj-orm/models/shared-spaces.dto';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { GenerateRegistrationLink } from '@/widgets/generate-registration-link';

import { Icon } from '../../icon';
import { Container } from '../../layout';

export const ShareDrawer = ({
  isOpen,
  onClose,
  collection,
  item,
}: {
  isOpen: boolean;
  onClose: (arg: boolean) => void;
  item: string;
  collection: CollectionName;
}) => {
  const [delegatingMnemonic, setDelegatingMnemonic] = useState<string | null>(
    null,
  );
  const [createAssistantMutation] = useCreateAssistantMutation();
  const { create, keyPairFromMnemonic } = useMnemonic();
  const { getSharedSpace } = useSharedSpaces();
  const { keyPair } = useKeyPairStore();
  const sjDb = useSJDatabase();
  const { createNotification } = useNotifications();

  const onCloseModal = () => {
    onClose(!isOpen);
  };

  const [selectedNextOwners, setSelectedNextOwners] = useState<string[]>([]);
  const { data: initialNextOwners } = useGetBeneficiariesQuery();
  const { addShared } = useShared();

  const nextOwners = useMemo(() => {
    const sjDnNextOwners = sjDb.collections.beneficiaries?.findMany(Boolean);
    if (!sjDnNextOwners?.length) return [];
    return initialNextOwners?.getBeneficiaries
      .filter((no) => !!no.publicKey)
      .map((no) => ({
        value: no.publicKey,
        label: `${sjDnNextOwners.find((i) => i.centralDbProfileId === no.id)
          ?.firstName} ${sjDnNextOwners.find(
          (i) => i.centralDbProfileId === no.id,
        )?.lastName}`,
      }));
  }, [sjDb]);

  const putKeys = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    shareItem: any,
    type: CollectionName,
    shouldNotify = false,
  ) => {
    const relatedItems = getDtoRelatedToTargetDto(sjDb, shareItem);
    const resultMap = new Map<CollectionName, Array<BaseDto>>();
    for (const [key, value] of relatedItems) {
      resultMap.set(key, Array.from(value));
    }

    for (const noPk of selectedNextOwners) {
      for (const [col, dtos] of resultMap.entries()) {
        for (const dto of dtos) {
          sjDb.collections[col]?.putPublicKey(dto.id, noPk);
          sjDb.collections[col]?.putPermission(dto.id, noPk, Permission.READ);
          sjDb.collections[col]?.putPermission(dto.id, noPk, Permission.WRITE);
        }
      }

      sjDb.collections[type]?.putPublicKey(shareItem.id, noPk);
      sjDb.collections[type]?.putPermission(
        shareItem.id,
        noPk,
        Permission.READ,
      );
      sjDb.collections[type]?.putPermission(
        shareItem.id,
        noPk,
        Permission.WRITE,
      );
      if (shouldNotify) {
        createNotification(
          { type: NotificationType.NEW_ITEM_SHARED },
          true,
          [noPk],
          false,
        );
      }
    }
  };

  const onShareClick = () => {
    if (selectedNextOwners.length === 0) return;

    const shareItem = sjDb.collections[collection]?.findOne(
      (i) => i.id === item,
    );

    if (!shareItem) return;

    putKeys(shareItem, collection);

    if (collection === CollectionName.BENEFICIARIES) {
      const sharedItem = shareItem as BeneficiaryDto;
      const relatedNextOwnerDocument = sjDb.collections[
        CollectionName.BENEFICIARY_PERSONAL_DATA_DOCUMENT
      ]?.findOne((i) => i.beneficiaryDtoId === sharedItem.id);

      putKeys(
        relatedNextOwnerDocument,
        CollectionName.BENEFICIARY_PERSONAL_DATA_DOCUMENT,
        true,
      );
    }

    if (collection !== CollectionName.SHARED_SPACES) {
      addShared(
        item,
        collection,
        selectedNextOwners
          .map(
            (no) =>
              initialNextOwners?.getBeneficiaries.find(
                (i) => no === i.publicKey,
              )?.id,
          )
          .filter((i) => !!i) as unknown as number[],
      );
    }

    if (collection === CollectionName.SHARED_SPACES) {
      const sharedItem = shareItem as SharedSpacesDto;
      const dtoIds = sharedItem.dtoIds;

      for (const dtoId of dtoIds) {
        const dtoIdShareItem = sjDb.collections[dtoId.type]?.findOne(
          (i) => i.id === dtoId.id,
        );

        if (!dtoIdShareItem) continue;

        putKeys(dtoIdShareItem, dtoId.type, true);
      }
    }

    onCloseModal();
  };

  const updateSharedSpace = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async (mnemonic: string) => {
      const { publicKey } = await keyPairFromMnemonic(mnemonic);

      const sharedSpace = getSharedSpace(item);

      if (sharedSpace) {
        const dtoIds = sharedSpace.dtoIds;

        for (const dtoId of dtoIds) {
          const dtoIdShareItem = sjDb.collections[dtoId.type]?.findOne(
            (i) => i.id === dtoId.id,
          );

          if (!dtoIdShareItem) continue;

          // const relatedItems = getDtoRelatedToTargetDto(sjDb, dtoIdShareItem);
          // const resultMap = new Map<CollectionName, Array<BaseDto>>();
          // for (const [key, value] of relatedItems) {
          //   resultMap.set(key, Array.from(value));
          // }
          //
          // for (const [col, dtos] of resultMap.entries()) {
          //   for (const dto of dtos) {
          //     console.log(dto);
          //     sjDb.collections[col]?.putPublicKey(dto.id, publicKey);
          //     sjDb.collections[col]?.putPermission(
          //       dto.id,
          //       publicKey,
          //       Permission.READ,
          //     );
          //     sjDb.collections[col]?.putPermission(
          //       dto.id,
          //       publicKey,
          //       Permission.WRITE,
          //     );
          //   }
          // }

          sjDb.collections[dtoId.type]?.putPublicKey(dtoId.id, publicKey);
          sjDb.collections[dtoId.type]?.putPermission(
            dtoId.id,
            publicKey,
            Permission.READ,
          );
          sjDb.collections[dtoId.type]?.putPermission(
            dtoId.id,
            publicKey,
            Permission.WRITE,
          );
        }

        sjDb.collections[collection]?.putPublicKey(item, publicKey);
        sjDb.collections[collection]?.putPermission(
          item,
          publicKey,
          Permission.READ,
        );
        sjDb.collections[collection]?.putPermission(
          item,
          publicKey,
          Permission.WRITE,
        );
      }
    },
    [item],
  );

  const onGenerateShareLink = useCallback(async () => {
    const { mnemonic, keyPair: assistantKeyPair } = await create();

    const creationResult = await createAssistantMutation({
      variables: {
        name: item,
        publicKey: assistantKeyPair.publicKey,
      },
    });

    if (!creationResult.data?.createAssistant) return;

    const assistant = sjDb.collections.assistantMnemonic?.create(
      {
        id: sjDb.generateId(CollectionName.ASSISTANT_MNEMONIC),
        assistantProfileId: String(creationResult.data.createAssistant.id),
        name: item,
        mnemonic,
      },
      [keyPair!.publicKey, assistantKeyPair.publicKey],
    );

    if (!assistant?.assistantProfileId) return;

    await updateSharedSpace(mnemonic);
    setDelegatingMnemonic(mnemonic);
  }, [sjDb, item]);

  useEffect(() => {
    if (collection === CollectionName.SHARED_SPACES) {
      const assistantMnemonic = sjDb.collections.assistantMnemonic?.findOne(
        (i) => i.name === item,
      );

      if (assistantMnemonic) {
        setDelegatingMnemonic(assistantMnemonic.mnemonic);
      }
    }
  }, [sjDb, item]);

  return (
    <Drawer
      placement={'bottom'}
      open={isOpen}
      onClose={onCloseModal}
      closable={false}
      height={'auto'}
    >
      <Row justify={'space-between'} align={'middle'}>
        <Typography.Title level={4} style={{ margin: 0 }}>
          Share
        </Typography.Title>

        <Icon
          icon="close"
          cursorPointer
          onClick={onCloseModal}
          color="rgba(6, 1, 54, 1)"
        />
      </Row>

      <Container marginTop={8}>
        <Typography.Text>
          You can share an asset, document or contact with Next Owners, granting
          them full access and editing rights
        </Typography.Text>
      </Container>

      <Container marginTop={24}>
        <Typography.Text>Select Next Owner(s) to share with</Typography.Text>
      </Container>

      <Container marginTop={4}>
        <Select
          options={nextOwners}
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Select Next Owner(s)"
          value={selectedNextOwners}
          onChange={(e) => setSelectedNextOwners(e)}
        />
      </Container>

      {collection === CollectionName.SHARED_SPACES && (
        <Container marginTop={20}>
          {!delegatingMnemonic && (
            <Button size="middle" type="primary" onClick={onGenerateShareLink}>
              Generate share link
            </Button>
          )}

          {delegatingMnemonic ? (
            <>
              <Container marginTop={30}>
                <Typography.Title level={5}>
                  Copy and send the link.
                </Typography.Title>
              </Container>

              <Container marginY={16}>
                <GenerateRegistrationLink
                  alias="sharedSpaceAssistant"
                  itemId={item}
                />
              </Container>

              <CopyComponent
                code={delegatingMnemonic}
                isGenerated={false}
                setIsGenerated={() => undefined}
              />
            </>
          ) : null}
        </Container>
      )}

      <Container marginTop={24}>
        <Button block size="large" type="primary" onClick={onShareClick}>
          Share
        </Button>
      </Container>
    </Drawer>
  );
};
