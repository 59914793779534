// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable sonarjs/no-duplicated-branches */
import { i18n } from 'next-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import _ from 'lodash';

import { FormsByCollection } from '@/_app/providers/form-drawer-provider';
import { isExported } from '@/shared/constants/constants';
import { PublicKey } from '@/shared/lib/secure-json/core/crypto-core/types';
import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import {
  Key,
  Permission,
} from '@/shared/lib/secure-json/core/secure-json-collection/types';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';

const exportedModeWriteErrorEvent = (collection: CollectionName) =>
  new CustomEvent('ExportedModeWriteErrorEvent', {
    detail: {
      collection,
    },
  });

export class ExportedModeWriteError extends Error {
  constructor(message: string, collection: CollectionName) {
    super(message);
    this.name = 'ExportedModeWriteError';
    self?.dispatchEvent?.(exportedModeWriteErrorEvent(collection));
  }
}

interface DiffResult {
  [key: string]:
    | {
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        oldValue: any;
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        newValue: any;
      }
    | DiffResult;
}

function diff<T extends object>(obj1: T, obj2: T): DiffResult {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  function changes(innerObj1: any, innerObj2: any): DiffResult {
    return Object.keys({ ...innerObj1, ...innerObj2 }).reduce<DiffResult>(
      (result, key) => {
        if (innerObj1[key] !== innerObj2[key]) {
          if (
            typeof innerObj1[key] === 'object' &&
            typeof innerObj2[key] === 'object' &&
            innerObj1[key] !== null &&
            innerObj2[key] !== null
          ) {
            const diffResult = changes(innerObj1[key], innerObj2[key]);
            if (Object.keys(diffResult).length > 0) {
              result[key] = diffResult;
            }
          } else if (innerObj1[key] !== innerObj2[key]) {
            result[key] = {
              oldValue: innerObj1[key],
              newValue: innerObj2[key],
            };
          }
        }
        return result;
      },
      {},
    );
  }

  return changes(obj1, obj2);
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function formatDiff(
  diffObj: DiffResult,
  collection: CollectionName,
  uniqKeyForSearch: string,
): string | false {
  const form_ =
    FormsByCollection[collection as unknown as keyof typeof FormsByCollection];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const form: any = [
    CollectionName.BENEFICIARIES,
    CollectionName.BENEFICIARY_PERSONAL_DATA_DOCUMENT,
  ].includes(collection)
    ? form_
    : uniqKeyForSearch
    ? form_?.[uniqKeyForSearch as unknown as keyof typeof form_]
    : undefined;

  function formatChanges(obj: DiffResult, prefix: string = ''): string[] {
    return Object.keys(obj).reduce<string[]>((result, key) => {
      const value = obj[key];
      let part: Record<
        string,
        {
          label?:
            | string
            | {
                prefix: string;
                postfix: string;
                fieldKey: string;
              };
        }
      > = {};

      for (const formPart of Array.isArray(form) ? form : []) {
        const partKeys = Object.keys(formPart);
        if (partKeys.includes(key)) {
          part = formPart;
        }
      }
      const partsLabel = part?.[key]?.label;
      const label_ = partsLabel
        ? typeof partsLabel === 'string'
          ? partsLabel
          : `${i18n?.t(partsLabel.prefix)}${i18n?.t(partsLabel.postfix)}`
        : key;
      const label = label_ ? i18n?.t(label_) : label_;
      if ('oldValue' in value && 'newValue' in value) {
        if (JSON.stringify(value.oldValue) === JSON.stringify(value.newValue)) {
          return result;
        }
        const oldValue = isEmpty(value.oldValue)
          ? '<empty object>'
          : JSON.stringify(value.oldValue);
        const newValue = isEmpty(value.newValue)
          ? '<empty object>'
          : JSON.stringify(value.newValue);

        if (!isEmpty(value.newValue) && newValue !== '{}') {
          result.push(`${prefix}${label}: ${oldValue} -> ${newValue}\n`);
        }
      } else {
        // eslint-disable-next-line no-param-reassign
        result = result.concat(
          formatChanges(value as DiffResult, `${prefix}${label}.\n`),
        );
      }
      return result;
    }, []);
  }

  const changes = formatChanges(diffObj);
  return changes.length > 0 ? changes.join('\n') : false;
}

export class SJCollection<T extends BaseDto> {
  private protectedKeys = [CollectionName.MIGRATIONS];

  private throwIfProtectedKey(): void {
    if (this.protectedKeys.includes(this.collectionName)) {
      throw new Error(`Collection ${this.collectionName} is protected`);
    }
  }

  private throwIfExportedMode(): void {
    if (isExported) {
      throw new ExportedModeWriteError(
        `Collection ${this.collectionName} is protected in exported mode`,
        this.collectionName,
      );
    }
  }

  constructor(
    private readonly collectionName: CollectionName,
    private readonly secureJsonBase: SecureJsonBase,
    private readonly setSecureJsonBase: (
      name: CollectionName,
      secureJsonBase: SecureJsonBase,
    ) => void,
    private readonly createLog: (
      publicData: object,
      dataForEncrypt: object | { name: string; description: string },
    ) => Promise<
      | {
          id: number;
          createdAt: Date;
          mainUserId: number;
          publicData: string;
          encryptedData: string;
        }
      | undefined
    >,
  ) {
    // log.trace('SJCollection constructor', this.protectedKeys);
  }

  size(): number {
    return this.secureJsonBase.items.size;
  }

  // eslint-disable-next-line complexity
  create(
    value: T,
    publicKeys: Array<PublicKey>,
    permissions?: Map<PublicKey, Set<Permission>>,
  ): T {
    this.throwIfProtectedKey();
    this.throwIfExportedMode();
    value.createdAt = new Date();
    value.updatedAt = new Date();
    this.secureJsonBase.set({
      key: value.id,
      data: value,
      publicKeys,
      permissions,
    });
    // log in format: [<timestamp>] Created new item in collection <collectionName>: <item>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const valueAsAny = value as any;
    switch (this.collectionName) {
      case CollectionName.ASSETS:
        this.createLog(
          {
            dtoId: value.id,
          },
          {
            name: `${
              valueAsAny.nickname ||
              valueAsAny.nickName ||
              valueAsAny.name ||
              ''
            } was created`,
            description: `[${new Date().toISOString()}] ${
              valueAsAny.nickname ||
              valueAsAny.name ||
              valueAsAny.nickName ||
              ''
            } was created`,
            collectionName: this.collectionName,
            dto: value,
          },
        );
        break;
      case CollectionName.SOWE:
        this.createLog(
          {
            dtoId: value.id,
          },
          {
            name: `${
              valueAsAny.nickname ||
              valueAsAny.name ||
              valueAsAny.nickName ||
              ''
            } was created`,
            description: `[${new Date().toISOString()}] ${
              valueAsAny.nickname ||
              valueAsAny.name ||
              valueAsAny.nickName ||
              ''
            } was created`,
            collectionName: this.collectionName,
            dto: value,
          },
        );
        break;
      case CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS:
        this.createLog(
          {
            dtoId: value.id,
          },
          {
            name: `${
              valueAsAny.nickname ||
              valueAsAny.name ||
              valueAsAny.nickName ||
              ''
            } was created`,
            description: `[${new Date().toISOString()}] ${
              valueAsAny.nickname ||
              valueAsAny.name ||
              valueAsAny.nickName ||
              ''
            } was created`,
            collectionName: this.collectionName,
            dto: value,
          },
        );
        break;
      case CollectionName.CONTACTS:
        this.createLog(
          {
            dtoId: value.id,
          },
          {
            name: `${
              valueAsAny.nickname ||
              valueAsAny.name ||
              valueAsAny.nickName ||
              ''
            } was created`,
            description: `[${new Date().toISOString()}] ${
              valueAsAny.nickname ||
              valueAsAny.name ||
              valueAsAny.nickName ||
              ''
            } was created`,
            collectionName: this.collectionName,
            dto: value,
          },
        );
        break;
      case CollectionName.PRIVATE_DOCUMENTS:
        this.createLog(
          {
            dtoId: value.id,
          },
          {
            name: `${
              valueAsAny.nickname ||
              valueAsAny.name ||
              valueAsAny.nickName ||
              ''
            } was created`,
            description: `[${new Date().toISOString()}] ${
              valueAsAny.nickname ||
              valueAsAny.name ||
              valueAsAny.nickName ||
              ''
            } was created`,
            collectionName: this.collectionName,
            dto: value,
          },
        );
        break;
      case CollectionName.DRAFTS:
      case CollectionName.MIGRATIONS:
      case CollectionName.NOTIFICATION:
        // ignore events
        break;
      default:
        // no log for other collections
        break;
    }
    this.commitCollection();
    return value;
  }

  createMany(values: T[], publicKeys: Array<PublicKey>): T[] {
    for (const value of values) this.create(value, publicKeys);
    this.commitCollection();
    return values;
  }

  // eslint-disable-next-line complexity,sonarjs/cognitive-complexity
  update(value: T): T {
    this.throwIfProtectedKey();
    this.throwIfExportedMode();

    const oldValue = this.findOne((item) => item.id === value.id);

    value.updatedAt = new Date();
    this.secureJsonBase.set({
      key: value.id,
      data: value,
    });

    if (oldValue) {
      const changes = diff(oldValue, value);
      const uniqKeyForSearch = (oldValue as { category?: string })
        ?.category as string;
      const formattedDiff = formatDiff(
        changes,
        this.collectionName,
        uniqKeyForSearch,
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newValueAsAny = value as any;

      if (formattedDiff) {
        switch (this.collectionName) {
          case CollectionName.ASSETS:
            this.createLog(
              {
                dtoId: value.id,
                eventType: 'update',
              },
              {
                name: `${
                  newValueAsAny.nickname ||
                  newValueAsAny.nickName ||
                  newValueAsAny.name ||
                  ''
                } was modified`,
                description: `[${new Date().toISOString()}] ${
                  newValueAsAny.nickname ||
                  newValueAsAny.nickName ||
                  newValueAsAny.name ||
                  ''
                } was modified. Changes:\n${formattedDiff}`,
                collectionName: this.collectionName,
                dto: value,
              },
            );
            break;
          case CollectionName.SOWE:
            this.createLog(
              {
                dtoId: value.id,
                eventType: 'update',
              },
              {
                name: `${
                  newValueAsAny.nickname ||
                  newValueAsAny.nickName ||
                  newValueAsAny.name ||
                  ''
                } was modified`,
                description: `[${new Date().toISOString()}] ${
                  newValueAsAny.nickname ||
                  newValueAsAny.nickName ||
                  newValueAsAny.name ||
                  ''
                } was modified. Changes:\n${formattedDiff}`,
                collectionName: this.collectionName,
                dto: value,
              },
            );
            break;
          case CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS:
            this.createLog(
              {
                dtoId: value.id,
                eventType: 'update',
              },
              {
                name: `${newValueAsAny.name} was modified`,
                description: `[${new Date().toISOString()}] ${
                  newValueAsAny.name || ''
                } was modified. Changes:\n${formattedDiff}`,
                collectionName: this.collectionName,
                dto: value,
              },
            );
            break;
          case CollectionName.SCENARIO_META_INFO:
            this.createLog(
              {
                dtoId: value.id,
                eventType: 'update',
              },
              {
                name: `${
                  newValueAsAny.nickname ||
                  newValueAsAny.nickName ||
                  newValueAsAny.name ||
                  ''
                } was modified`,
                description: `[${new Date().toISOString()}] ${
                  newValueAsAny.nickname ||
                  newValueAsAny.nickName ||
                  newValueAsAny.name ||
                  ''
                } was modified. Changes:\n${formattedDiff}`,
                collectionName: this.collectionName,
                dto: value,
              },
            );
            break;
          case CollectionName.BENEFICIARIES:
            this.createLog(
              {
                dtoId: value.id,
                eventType: 'update',
              },
              {
                name: `${
                  newValueAsAny.nickname ||
                  newValueAsAny.nickName ||
                  newValueAsAny.name ||
                  ''
                } was modified`,
                description: `[${new Date().toISOString()}] ${
                  newValueAsAny.nickname ||
                  newValueAsAny.nickName ||
                  newValueAsAny.name ||
                  ''
                } was modified. Changes:\n${formattedDiff}`,
                collectionName: this.collectionName,
                dto: value,
              },
            );
            break;
          case CollectionName.CONTACTS:
            this.createLog(
              {
                dtoId: value.id,
                eventType: 'update',
              },
              {
                name: `${
                  newValueAsAny.nickname ||
                  newValueAsAny.nickName ||
                  newValueAsAny.name ||
                  ''
                } was modified`,
                description: `[${new Date().toISOString()}] ${
                  newValueAsAny.nickname ||
                  newValueAsAny.nickName ||
                  newValueAsAny.name ||
                  ''
                } was modified. Changes:\n${formattedDiff}`,
                collectionName: this.collectionName,
                dto: value,
              },
            );
            break;
          case CollectionName.PRIVATE_DOCUMENTS:
            this.createLog(
              {
                dtoId: value.id,
                eventType: 'update',
              },
              {
                name: `${
                  newValueAsAny.nickname ||
                  newValueAsAny.nickName ||
                  newValueAsAny.name ||
                  ''
                } was modified`,
                description: `[${new Date().toISOString()}] ${
                  newValueAsAny.nickname ||
                  newValueAsAny.nickName ||
                  newValueAsAny.name ||
                  ''
                } was modified. Changes:\n${formattedDiff}`,
                collectionName: this.collectionName,
                dto: value,
              },
            );
            break;
          case CollectionName.DRAFTS:
          case CollectionName.MIGRATIONS:
          case CollectionName.NOTIFICATION:
          case CollectionName.DELEGATING_REQUESTS:
          case CollectionName.TUTORIALS:
            // ignore events
            break;
          default:
            // no log for other collections
            break;
        }
      }
    }

    this.commitCollection();
    return value;
  }

  updateMany(values: T[]): T[] {
    for (const value of values) this.update(value);
    this.commitCollection();
    return values;
  }

  findOne(
    condition: (item: T) => boolean,
    sort?: (a: T, b: T) => number,
    skip?: number,
    limit?: number,
  ): T | undefined {
    let collection = this.secureJsonBase
      .values()
      .filter((value) => value.decryptedData);

    if (sort) {
      collection = collection.sort((value1, value2) =>
        sort(value1.decryptedData, value2.decryptedData),
      );
    }

    if (skip) {
      collection = collection.slice(skip, collection.length);
    }

    if (limit) {
      collection = collection.slice(0, limit);
    }

    const result = collection.find((value) => condition(value.decryptedData));

    return result?.decryptedData
      ? _.cloneDeep<T>(result.decryptedData as unknown as T)
      : undefined;
  }

  findMany(
    condition: (item: T) => boolean,
    sort?: (a: T, b: T) => number,
    skip?: number,
    limit?: number,
  ): T[] {
    let collection = this.secureJsonBase
      .values()
      .filter((value) => value.decryptedData);

    if (sort) {
      collection = collection.sort((value1, value2) =>
        sort(value1.decryptedData, value2.decryptedData),
      );
    }

    if (skip) {
      collection = collection.slice(skip, collection.length);
    }

    if (limit) {
      collection = collection.slice(0, limit);
    }

    const result = collection
      .filter((value) => condition(value.decryptedData))
      .map((value) => value.decryptedData);

    return result ? _.cloneDeep<T[]>(result as unknown as T[]) : [];
  }

  // eslint-disable-next-line complexity
  remove(value: T): T {
    this.throwIfProtectedKey();
    this.throwIfExportedMode();
    const oldValueAsAny = cloneDeep(
      this.findOne((item) => item.id === value.id),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any;
    this.secureJsonBase.delete(value.id);

    if (oldValueAsAny) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      switch (this.collectionName) {
        case CollectionName.ASSETS:
          this.createLog(
            {},
            {
              name: `${oldValueAsAny.nickName} was deleted`,
              description: `[${new Date().toISOString()}] ${
                oldValueAsAny.nickName ?? ''
              } was deleted`,
              collectionName: this.collectionName,
              dto: value,
            },
          );
          break;
        case CollectionName.SOWE:
          this.createLog(
            {},
            {
              name: `${oldValueAsAny.name} was deleted`,
              description: `[${new Date().toISOString()}] ${
                oldValueAsAny.name ?? ''
              } was deleted`,
              collectionName: this.collectionName,
              dto: value,
            },
          );
          break;
        case CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS:
          this.createLog(
            {},
            {
              name: `${oldValueAsAny.name} was deleted`,
              description: `[${new Date().toISOString()}] ${
                oldValueAsAny.name ?? ''
              } was deleted`,
              collectionName: this.collectionName,
              dto: value,
            },
          );
          break;
        case CollectionName.CONTACTS:
          this.createLog(
            {},
            {
              name: `${oldValueAsAny.nickname} was deleted`,
              description: `[${new Date().toISOString()}] ${
                oldValueAsAny.nickname ?? ''
              } was deleted`,
              collectionName: this.collectionName,
              dto: value,
            },
          );
          break;
        case CollectionName.PRIVATE_DOCUMENTS:
        case CollectionName.UNKNOWN_DOCUMENT:
        case CollectionName.ASSET_INFORMATION_DOCUMENT:
        case CollectionName.BENEFICIARY_PERSONAL_DATA_DOCUMENT:
        case CollectionName.PASSPORT_DOCUMENT:
          this.createLog(
            {},
            {
              name: `${oldValueAsAny.name} was deleted`,
              description: `[${new Date().toISOString()}] ${
                oldValueAsAny.name ?? ''
              } was deleted`,
              collectionName: this.collectionName,
              dto: value,
            },
          );
          break;
        case CollectionName.DRAFTS:
        case CollectionName.MIGRATIONS:
        case CollectionName.NOTIFICATION:
        case CollectionName.TUTORIALS:
          // ignore events
          break;
        default:
          // no log for other collections
          break;
      }
    }
    this.commitCollection();
    return value;
  }

  removeMany(condition: (item: T) => boolean): T[] {
    const values = this.findMany(condition);

    for (const value of values) this.remove(value);

    this.commitCollection();
    return values;
  }

  removeAll(): Key[] {
    this.throwIfProtectedKey();
    this.throwIfExportedMode();
    const keys = this.secureJsonBase.keys();

    for (const key of keys) {
      if (key) this.secureJsonBase.delete(key);
    }

    this.commitCollection();
    return keys;
  }

  // Public keys
  public getPublicKeys(key: Key): PublicKey[] | undefined {
    return this.secureJsonBase.getPublicKeys(key);
  }

  public putPublicKey(id: Key, publicKey: PublicKey): void {
    this.throwIfProtectedKey();
    this.throwIfExportedMode();
    this.secureJsonBase.putPublicKey(id, publicKey);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const valueAsAny = this.findOne((item) => item.id === id) as any;
    switch (this.collectionName) {
      case CollectionName.ASSETS:
        this.createLog(
          {},
          {
            name: `${valueAsAny.nickName}`,
            description: `[${new Date().toISOString()}] ${publicKey} was assigned to ${id}`,
            collectionName: this.collectionName,
            dtoId: id,
            publicKey,
          },
        );
        break;
      case CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS:
        this.createLog(
          {},
          {
            name: `${valueAsAny.nickname}`,
            description: `[${new Date().toISOString()}] ${publicKey} was assigned to ${id}`,
            collectionName: this.collectionName,
            dtoId: id,
            publicKey,
          },
        );
        break;
      case CollectionName.SOWE:
        this.createLog(
          {},
          {
            name: `${valueAsAny.name}`,
            description: `[${new Date().toISOString()}] ${publicKey} was assigned to ${id}`,
            collectionName: this.collectionName,
            dtoId: id,
            publicKey,
          },
        );
        break;
      case CollectionName.CONTACTS:
        this.createLog(
          {},
          {
            name: `${valueAsAny.nickName}`,
            description: `[${new Date().toISOString()}] ${publicKey} was assigned to ${id}`,
            collectionName: this.collectionName,
            dtoId: id,
            publicKey,
          },
        );
        break;
      case CollectionName.PRIVATE_DOCUMENTS:
        this.createLog(
          {},
          {
            name: `${valueAsAny.nickName}`,
            description: `[${new Date().toISOString()}] ${publicKey} was assigned to ${id}`,
            collectionName: this.collectionName,
            dtoId: id,
            publicKey,
          },
        );
        break;
      default:
      // no log for other collections
    }
    this.commitCollection();
  }

  public deletePublicKey(id: Key, publicKey: PublicKey): void {
    this.secureJsonBase.deletePublicKey(id, publicKey);
    // this.createLog(
    //   {},
    //   {
    //     name: `Deleted public key from collection ${this.collectionName}`,
    //     description: `[${new Date().toISOString()}] Deleted public key from collection ${
    //       this.collectionName
    //     }: ${publicKey}`,
    //     collectionName: this.collectionName,
    //     dtoId: id,
    //     publicKey,
    //   },
    // );
    this.commitCollection();
  }

  // Permissions

  public getPermissions(key: Key): Map<PublicKey, Set<Permission>> | undefined {
    return this.secureJsonBase.getPermissions(key);
  }

  public putPermission(
    key: Key,
    publicKey: PublicKey,
    permission: Permission,
  ): void {
    this.secureJsonBase.putPermission(key, publicKey, permission);
    // const value = this.findOne((item) => item.id === key);
    // this.createLog(
    //   {},
    //   {
    //     name: `${value}`,
    //     description: `[${new Date().toISOString()}] Added new permission to collection ${
    //       this.collectionName
    //     }: ${permission}`,
    //     collectionName: this.collectionName,
    //     dtoId: key,
    //     publicKey,
    //     permission,
    //   },
    // );
    this.commitCollection();
  }

  public deletePermission(
    key: Key,
    publicKey: PublicKey,
    permission: Permission,
  ): void {
    this.secureJsonBase.deletePermission(key, publicKey, permission);
    // this.createLog(
    //   {},
    //   {
    //     name: `Deleted permission from collection ${this.collectionName}`,
    //     description: `[${new Date().toISOString()}] Deleted permission from collection ${
    //       this.collectionName
    //     }: ${permission}`,
    //     collectionName: this.collectionName,
    //     dtoId: key,
    //     publicKey,
    //     permission,
    //   },
    // );
    this.commitCollection();
  }

  // Commit collection

  private commitCollection(): void {
    this.throwIfProtectedKey();
    this.throwIfExportedMode();
    this.setSecureJsonBase(this.collectionName, this.secureJsonBase);
  }
}
