import { useCallback, useMemo } from 'react';

import { useCreateLogMutation, useGetEventLogsCountLazyQuery, useGetLogsLazyQuery } from '@/shared/generated/graphql';
import { CryptoCore } from '@/shared/lib/secure-json/core/crypto-core';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { log } from '@/shared/utils/log';

export function useEventLog() {
  const [createLogMutation] = useCreateLogMutation();
  const [loadLogs] = useGetLogsLazyQuery({ fetchPolicy: 'no-cache' });
  const [loadLogsCount] = useGetEventLogsCountLazyQuery({ fetchPolicy: 'no-cache' });
  const keyPairStore = useKeyPairStore();
  const cryptoCore = new CryptoCore();

  const getLogsCount = useCallback(async (from: Date, to: Date, dtoId?: string) => {
    return (await loadLogsCount({
      variables: dtoId ? { from, to, forDtoId: dtoId } : { from, to },
      fetchPolicy: 'no-cache',
    })).data?.getEventLogs.totalCount ?? 0;
  }, [loadLogsCount]);


  const createLog = useCallback(
    async (publicData: object, dataForEncrypt: object | { name: string, description: string }) => {
      try {
        const decryptedSymmetricKey = cryptoCore.aes.generateKey();
        const encryptedSymmetricKey = cryptoCore.rsa.encrypt(
          decryptedSymmetricKey,
          keyPairStore.keyPair!.publicKey,
        );
        const publicDataString = JSON.stringify({
          ...publicData,
          __encryptedSymmetricKey: encryptedSymmetricKey,
        });
        const dataForEncryptString = JSON.stringify(dataForEncrypt);

        const encryptedData = cryptoCore.aes.encrypt(
          dataForEncryptString,
          decryptedSymmetricKey,
        );

        const { data } = await createLogMutation({
          variables: { publicData: publicDataString, encryptedData },
        });
        return data?.createEventLog;
      } catch (e) {
        log.error('[EVENT_LOG]: Error create event log', e);
        return undefined;
      }
    },
    [keyPairStore.keyPair],
  );

  const getLogs = useCallback(
    async (
      fromDateTime: Date,
      toDateTime: Date,
      take: number,
      skip: number,
      dtoId?: string,
    ) => {
      const { data } = await loadLogs({
        variables: dtoId ? { fromDateTime, toDateTime, take, skip, forDtoId: dtoId } : {
          fromDateTime,
          toDateTime,
          take,
          skip,
        },
        fetchPolicy: 'no-cache',
      });
      const items = data?.getEventLogs?.items.map((log) => {
        try {
          // symmetric key is stored encrypted via RSA or stored in the public data

          const publicData = JSON.parse(log.publicData);

          const decryptedSymmetricKey = cryptoCore.rsa.decrypt(
            publicData.__encryptedSymmetricKey,
            keyPairStore.keyPair!.privateKey,
          );

          const decryptedData = cryptoCore.aes.decrypt(
            log.encryptedData,
            decryptedSymmetricKey,
          );

          return {
            ...log,
            encryptedData: JSON.parse(decryptedData) as { name: string, description: string },
            publicData,
          };
        } catch (e) {
          console.warn('[EVENT_LOG]: Error decrypt event log', e);
          return { ...log, encryptedData: {} as { name: string, description: string }, publicData: {} };
        }
      });


      return {
        totalCount: data?.getEventLogs?.totalCount,
        items,
      };
    },
    [loadLogs, keyPairStore.keyPair],
  );

  return useMemo(() => ({
    createLog,
    getLogs,
    getLogsCount,
  }), [createLog, getLogs, getLogsCount]);
}
